import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../reward_rules/form';

export default {
  extends: TablePage,
  components: { Form },
  data() {
    return {
      requestType: 'POST',
    };
  },
  mounted() {
    this.getConfigList('page_list');
  },
  methods: {
    // 弹框操作
    modalClick({ val, row }) {
      // 启用禁用
      if (val.code === 'enable' || val.code === 'disable') {
        if (!this.selection || this.selection.length === 0) {
          return this.$message.error('请至少选择一条数据');
        }
        request.post(`/tpm/tpmRewardPoolRuleDetail/${val.code}`, this.selection).then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.getList();
          }
        });
      }
      if (val.code === 'view') {
        this.formConfig = {
          code: val.code,
          row,
          type: 'detail',
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
